import { Roles } from '../model/role.interface';
import { SidemenuCategory } from '../model/sidemenu-categories.interface';

export const COMPANY_SIDE_BAR: SidemenuCategory[] = [
  {
    expand: false,
    links: ['/dashboard'],
    title: 'SIDEBAR.DASHBOARD_TITLE',
    children: [
      {
        title: 'SIDEBAR.VISIT_DASHBOARD_LINK',
        link: '/dashboard/company',
        queryParams: true
      }
    ]
  },
  {
    expand: false,
    links: ['/recruitment'],
    title: 'SIDEBAR.RECRUITMENT_BOARD_TITLE',
    children: [
      {
        title: 'SIDEBAR.HANDLE_CANDIDATES_LINK',
        link: '/recruitment/handle-candidates',
        queryParams: true
      }
    ]
  },
  {
    expand: false,
    links: ['/jobs', '/templates'],
    title: 'SIDEBAR.JOBS_TITLE',
    children: [
      {
        title: 'SIDEBAR.CREATE_NEW_JOB_LINK',
        link: '/jobs/job-template-list',
        queryParams: true
      },
      {
        title: 'SIDEBAR.CREATE_NEW_TEMPLATE_LINK',
        link: '/templates/create',
        queryParams: true
      },
      {
        title: 'SIDEBAR.VIEW_CURRENT_JOBS_LINK',
        link: '/jobs/job-list',
        queryParams: true
      },
      {
        title: 'SIDEBAR.VIEW_CURRENT_TEMPLATES_LINK',
        link: '/templates/list',
        queryParams: true
      },
      {
        title: 'SIDEBAR.VIEW_DRAFT_JOBS_LINK',
        link: '/jobs/job-draft-list',
        queryParams: true
      },
      {
        title: 'SIDEBAR.VIEW_CURRENT_JOBS_TO_BIND_LINK',
        link: '/jobs/job-bind-list',
        allowForRoles: [Roles.companyHR, Roles.enterpriseHR, Roles.enterpriseRM, Roles.companyRM],
        queryParams: true
      },
    ]
  },
  {
    expand: false,
    links: ['/jobs'],
    title: 'SIDEBAR.ALTERNATIVE_JOBS',
    children: [
      {
        title: 'SIDEBAR.ALTERNATIVE_JOBS_CREATE',
        link: '/jobs/alternative-jobs/alternative-jobs-create',
        queryParams: true
      },
      {
        title: 'SIDEBAR.ALTERNATIVE_JOBS_EDIT',
        link: '/jobs/alternative-jobs/alternative-jobs-list',
        queryParams: true
      }
    ]
  },
  {
    expand: false,
    links: ['/requisitions'],
    title: 'SIDEBAR.REQUESITION_FORM',
    children: [
      {
        title: 'SIDEBAR.CREATE_NEW_REQUESITION',
        link: '/requisitions/create-new-requisition',
        queryParams: true
      },
      {
        title: 'SIDEBAR.CREATE_REQUESITION_FORM',
        link: '/requisitions/requisition-form-create',
        allowForRoles: [Roles.companyHR, Roles.enterpriseHR, Roles.enterpriseRM],
        queryParams: true
      },
      {
        title: 'SIDEBAR.EDIT_REQUISITION_FORM',
        link: '/requisitions/requisition-form-list',
        queryParams: true
      },
      {
        title: 'SIDEBAR.YOUR_REQUISITIONS',
        link: '/requisitions/your-requisitions',
        queryParams: true
      },
      {
        title: 'SIDEBAR.OTHERS_REQUISITION',
        link: '/requisitions/others-requisitions',
        queryParams: true
      }
    ]
  },
  {
    expand: false,
    links: ['/interview'],
    title: 'SIDEBAR.INTERVIEWS_BOARD_TITLE',
    children: [
      {
        title: 'SIDEBAR.CREATE_INDIVIDUAL_INTERVIEW',
        link: '/interview/application-interview',
        queryParams: true
      },
      {
        title: 'SIDEBAR.CREATE_GROUP_INTERVIEW',
        link: '/interview/group-interview',
        queryParams: true
      },
      {
        title: 'SIDEBAR.HANDLE_INDIVIDUAL_INTERVIEWS',
        link: '/interview/application-interview-list',
        queryParams: true
      },
      {
        title: 'SIDEBAR.HANDLE_GROUP_INTERVIEWS',
        link: '/interview/group-interview-list',
        queryParams: true
      },
      {
        title: 'SIDEBAR.INTERVIEW_LIBRARY',
        link: '/interview/interview-library',
        queryParams: true
      }
    ]
  },
  {
    expand: false,
    links: ['/marketplace'],
    title: 'SIDEBAR.MARKETPLACE_TITLE',
    children: [
      {
        title: 'SIDEBAR.STAFFING_AND_RECRUITMENT_LINK',
        link: '/marketplace/staffing-and-recruitment',
        queryParams: true
      }
    ]
  },
  {
    expand: false,
    links: ['/support-and-feedback', '/users', '/hr'],
    title: 'SIDEBAR.SETTINGS_TITLE',
    children: [
      {
        title: 'SIDEBAR.CREATE_NEW_USER_AND_BRANCH_LINK',
        link: '/users/user-branch-management',
        allowForRoles: [Roles.companyHR, Roles.enterpriseHR, Roles.enterpriseRM],
        queryParams: true
      },
      {
        title: 'SHARED.USER_PROFILE',
        link: '/users/user-profile',
        allowForRoles: [Roles.companyHR, Roles.companyRM, Roles.recruiter]
      },
      {
        title: 'SIDEBAR.SUPPORT_AND_FEEDBACK_LINK',
        link: '/support-and-feedback',
        queryParams: true
      },
      {
        title: 'SIDEBAR.HR',
        link: '/hr/dashboard',
        queryParams: true,
        class: 'd-none d-xl-block',
        allowForRoles: [Roles.companyHR, Roles.enterpriseHR, Roles.enterpriseRM]
      },
      {
        title: 'SIDEBAR.AGREEMENT_FORM_LIST',
        link: '/agreements/agreement-list',
        queryParams: true,
        hide: true
      }
    ]
  },
  {
    expand: false,
    links: ['/kpi-statistics'],
    title: 'SIDEBAR.KPI_AND_STATISTIC_TITLE',
    children: [
      {
        title: 'SIDEBAR.VIEW_KPI_AND_STATISTIC_LINK',
        link: '/kpi-statistics/kpi-select-jobs',
        queryParams: true,
      }
    ]
  },
];

export const COMPANY_SIDE_BAR_WITHOUT_INTERVIEW: SidemenuCategory[] =
  COMPANY_SIDE_BAR.filter(category => category.title !== 'SIDEBAR.INTERVIEWS_BOARD_TITLE');
