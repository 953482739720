import {
  initializeAmsJobForm,
  initializeBlocketJobForm,
  initializeDuunitoriJobForm,
  initializeFinnJobForm,
  initializeIndeedJobForm,
  initializeFacebookAndInstagramJobForm,
  initializeJobindexJobForm,
  initializeNavJobForm,
  initializeVerkstadsjobbJobForm,
  initializeAdwayJobForm,
  initializeBesokslivjobbJobForm,
  initializeJobnetJobForm,
  initializeLinkedInJobForm,
  initializeTikTokJobForm,
  initializeHigherChargeableJobForm
} from './job-forms';
import {
  AMS_FIELDS_TO_DISABLE,
  BLOCKET_FIELDS_TO_DISABLE,
  FINN_FIELDS_TO_DISABLE,
  INDEED_FIELDS_TO_DISABLE,
  NAV_FIELDS_TO_DISABLE,
  JOBINDEX_FIELDS_TO_DISABLE,
  DUUNITORI_FIELDS_TO_DISABLE,
  AMS_FIELDS_EXPERIENCE_REQUIRED,
  FACEBOOK_AND_INSTAGRAM_FIELDS_TO_DISABLE,
  VERKSTADSJOBB_FIELDS_TO_DISABLE,
  ADWAY_FIELDS_TO_DISABLE,
  BESOKSLIV_FIELDS_TO_DISABLE,
  JOBNET_FIELDS_TO_DISABLE,
  HIGHER_FIELDS_TO_DISABLE,
  LINKEDIN_FIELDS_TO_DISABLE,
  TIKTOK_FIELDS_TO_DISABLE
} from './platform-specific-fields';
import { FormGroup } from '@angular/forms';
import { DuunitoriCompanyInfo } from '../model/company.interface';

export enum JobPlatforms {
  higher = 'Higher',
  ams = 'Ams',
  blocket = 'Blocket',
  finn = 'Finn',
  indeed = 'Indeed',
  nav = 'Nav',
  jobindex = 'Jobindex',
  duunitori = 'Duunitori',
  facebookAndInstagram = 'FacebookAndInstagram',
  verkstadsjobb = 'Verkstadsjobb',
  adway = 'Adway',
  besoksliv = 'Besoksliv',
  jobnet = 'Jobnet',
  tiktok = 'Tiktok',
  linkedIn = 'LinkedIn',
  higherChargeable = 'HigherChannels',
  higherAlternativeJob = 'HigherAlternativeJob'
}
export interface JobPlatformPublish {
  name: string;
  jobFormName: string;
  templateFormName: string;
  initForm: () => FormGroup;
  fieldsToDisable: string[];
  order: number;
}

export type JobPlatformsPublish = Map<JobPlatforms, JobPlatformPublish>;

export const JOB_PLATFORMS_PUBLISH: JobPlatformsPublish = new Map([
  [
    JobPlatforms.ams,
    {
      name: 'ams',
      jobFormName: 'amsJob',
      templateFormName: 'amsJobTemplate',
      initForm: initializeAmsJobForm,
      fieldsToDisable: [...AMS_FIELDS_TO_DISABLE, ...AMS_FIELDS_EXPERIENCE_REQUIRED],
      order: 6
    }
  ],
  [
    JobPlatforms.blocket,
    {
      name: 'blocket',
      jobFormName: 'blocketJob',
      templateFormName: 'blocketJobTemplate',
      initForm: initializeBlocketJobForm,
      fieldsToDisable: BLOCKET_FIELDS_TO_DISABLE,
      order: 7
    }
  ],
  [
    JobPlatforms.finn,
    {
      name: 'finn',
      jobFormName: 'finnJob',
      templateFormName: 'finnJobTemplate',
      initForm: initializeFinnJobForm,
      fieldsToDisable: FINN_FIELDS_TO_DISABLE,
      order: 11
    }
  ],
  [
    JobPlatforms.indeed,
    {
      name: 'indeed',
      jobFormName: 'indeedJob',
      templateFormName: 'indeedJobTemplate',
      initForm: initializeIndeedJobForm,
      fieldsToDisable: INDEED_FIELDS_TO_DISABLE,
      order: 2
    }
  ],
  [
    JobPlatforms.nav,
    {
      name: 'nav',
      jobFormName: 'navJob',
      templateFormName: 'navJobTemplate',
      initForm: initializeNavJobForm,
      fieldsToDisable: NAV_FIELDS_TO_DISABLE,
      order: 12
    }
  ],
  [
    JobPlatforms.jobindex,
    {
      name: 'jobindex',
      jobFormName: 'jobindexJob',
      templateFormName: 'jobindexJobTemplate',
      initForm: initializeJobindexJobForm,
      fieldsToDisable: JOBINDEX_FIELDS_TO_DISABLE,
      order: 14
    }
  ],
  [
    JobPlatforms.duunitori,
    {
      name: 'Duunitori',
      jobFormName: 'duunitoriJob',
      templateFormName: 'duunitoriJobTemplate',
      initForm: initializeDuunitoriJobForm,
      fieldsToDisable: DUUNITORI_FIELDS_TO_DISABLE,
      order: 10
    }
  ],
  [
    JobPlatforms.facebookAndInstagram,
    {
      name: 'facebookAndInstagram',
      jobFormName: 'facebookAndInstagramJob',
      templateFormName: 'facebookAndInstagramJobTemplate',
      initForm: initializeFacebookAndInstagramJobForm,
      fieldsToDisable: FACEBOOK_AND_INSTAGRAM_FIELDS_TO_DISABLE,
      order: 3
    }
  ],
  [ JobPlatforms.verkstadsjobb,
    {
      name: 'verkstadsjobb',
      jobFormName: 'verkstadsjobbJob',
      templateFormName: 'verkstadsjobbJobTemplate',
      initForm: initializeVerkstadsjobbJobForm,
      fieldsToDisable: VERKSTADSJOBB_FIELDS_TO_DISABLE,
      order: 9
    }
  ],
  [ JobPlatforms.adway,
    {
      name: 'adway',
      jobFormName: 'adwayJob',
      templateFormName: 'adwayJobTemplate',
      initForm: initializeAdwayJobForm,
      fieldsToDisable: ADWAY_FIELDS_TO_DISABLE,
      order: 15
    }
  ],
  [ JobPlatforms.besoksliv,
    {
      name: 'besoksliv',
      jobFormName: 'besokslivJob',
      templateFormName: 'besokslivJobTemplate',
      initForm: initializeBesokslivjobbJobForm,
      fieldsToDisable: BESOKSLIV_FIELDS_TO_DISABLE,
      order: 8
    }
  ],
  [ JobPlatforms.jobnet,
    {
      name: 'jobnet',
      jobFormName: 'jobnetJob',
      templateFormName: 'jobnetJobTemplate',
      initForm: initializeJobnetJobForm,
      fieldsToDisable: JOBNET_FIELDS_TO_DISABLE,
      order: 13
    }
  ],
  [ JobPlatforms.tiktok,
    {
      name: 'tiktok',
      jobFormName: 'tikTokJob',
      templateFormName: 'tikTokJobTemplate',
      initForm: initializeTikTokJobForm,
      fieldsToDisable: TIKTOK_FIELDS_TO_DISABLE,
      order: 4
    }
  ],
  [ JobPlatforms.linkedIn,
    {
      name: 'linkedIn',
      jobFormName: 'linkedInJob',
      templateFormName: 'linkedInJobTemplate',
      initForm: initializeLinkedInJobForm,
      fieldsToDisable: LINKEDIN_FIELDS_TO_DISABLE,
      order: 5
    }
  ],
  [ JobPlatforms.higherChargeable,
    {
      name: 'higher',
      jobFormName: 'higherChannelsJob',
      templateFormName: 'higherChannelsJobTemplate',
      initForm: initializeHigherChargeableJobForm,
      fieldsToDisable: HIGHER_FIELDS_TO_DISABLE,
      order: 1
    }
  ]
]);

export const DuunitoriCampaigns: DuunitoriCompanyInfo[] = [
  {
    campaign: 'Standard',
    credits: 2,
    paymentMethod: '925 EUR',
    disc: ['COMPANY_INFO.STANDARD_CAMPAIGN_FIRST', 'COMPANY_INFO.STANDARD_CAMPAIGN_SECOND'],
    name: '925 EUR + VAT 24%'
  },
  {
    campaign: 'Premium',
    credits: 3,
    paymentMethod: '1300 EUR',
    name: '1300 EUR + VAT 24%',
    disc: ['COMPANY_INFO.PREMIUM_CAMPAIGN_FIRST', 'COMPANY_INFO.PREMIUM_CAMPAIGN_SECOND'],
  },
  {
    campaign: 'Super',
    credits: 5,
    paymentMethod: '1900 EUR',
    name: '1900 EUR + VAT 24%',
    disc: ['COMPANY_INFO.SUPER_CAMPAIGN_FIRST', 'COMPANY_INFO.SUPER_CAMPAIGN_SECOND', 'COMPANY_INFO.SUPER_CAMPAIGN_THIRD'],
  },
  {
    campaign: 'Hyper',
    credits: 8,
    paymentMethod: '3000 EUR',
    name: '3000 EUR + VAT 24%',
    disc: ['COMPANY_INFO.HYPER_CAMPAIGN_FIRST', 'COMPANY_INFO.HYPER_CAMPAIGN_SECOND'],
  },
  {
    campaign: 'Ultra',
    credits: 18,
    paymentMethod: '6750 EUR',
    name: '6750 EUR + VAT 24%',
    disc: ['COMPANY_INFO.ULTRA_CAMPAIGN_FIRST', 'COMPANY_INFO.ULTRA_CAMPAIGN_SECOND'],
  }
];
