import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { from, Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { LoaderService } from './loader.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from './user.service';
import { Pagination } from '../model/pagination.interface';
import { TranslateService } from '@ngx-translate/core';
import { Token } from '../model/token.interface';
import { ModalService } from './modal.service';
import { SetupService } from './setup.service';
import { EnterpriseService } from './enterprise.service';
import { CompanyService } from './company.service';
import { User } from '../model/user.interface';
import { Roles } from '../model/role.interface';
import { ExportService } from './export.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

  private _canLogout = true;
  private _isLogoutStarted = false;
  private _userName: string;
  private _userPass: string;

  constructor(
    private http: HttpClient,
    private router: Router,
    private loaderService: LoaderService,
    private toastr: ToastrService,
    private userService: UserService,
    private translateService: TranslateService,
    private modalService: ModalService,
    private setupService: SetupService,
    private enterpriseService: EnterpriseService,
    private companyService: CompanyService,
    private exportService: ExportService

  ) { }

  get isLoggedIn(): boolean {
    return !!this.userService.user && !!localStorage.getItem('token');
  }

  set canLogout(value: boolean) {
    this._canLogout = value;
  }

  get isLogoutStarted(): boolean {
    return this._isLogoutStarted;
  }

  set isLogoutStarted(value: boolean) {
    this._isLogoutStarted = value;
  }

  set userCredentials(credentials: { username: string, password: string }) {
    this._userName = credentials.username;
    this._userPass = credentials.password;
  }

  get userName(): string {
    return this._userName;
  }

  get userPassword(): string {
    return this._userPass;
  }

  login(username: string, password: string): Observable<HttpResponse<any>> {
    return this.http.post(environment.auth, { username, password }, { observe: 'response' })
      .pipe(
        tap(({ status, body }: HttpResponse<Token>) => {
          if (status === 200) {
            this.storeTokens(body.token, body.refreshToken);
          } else {
            this.userCredentials = { username, password };
          }
        })
      );
  }

  beforeLogout(): void {
    this._isLogoutStarted = true;
    if (this._canLogout) {
      this.logout().subscribe(() => this.router.navigate(['/login']));
    } else {
      this.router.navigate(['/login']);
    }
  }

  logout(): Observable<void> {
    return this.http.delete(environment.logout)
      .pipe(
        tap(() => {
          this.modalService.removeAllModals();
          this.setupService.setLogo = '';
          this.exportService.resetTilTid();
        }),
        map(() => {
          this.removeTokens();
          this.userService.removeCurrentUser();
          this._isLogoutStarted = false;
        }),
        catchError((error) => {
          this.removeTokens();
          this.userService.removeCurrentUser();
          this._isLogoutStarted = false;
          return throwError(error);
        })
      );
  }

  requestResetPasswordLink(email: string): Observable<Pagination<string>> {
    return this.http.get<Pagination<string>>(`${environment.forgottenPassword}/${email}`);
  }

  resetPassword(newPassword: string, resetToken: string): Observable<Pagination<string>> {
    const payload = {
      resetPasswordToken: resetToken,
      newPassword
    };

    return this.http.put<Pagination<string>>(environment.resetPassword, payload);
  }

  refreshTokens(): Observable<Token> {
    const refToken = localStorage.getItem('refreshToken');

    return this.http.post<Token>(environment.refresh, { refreshToken: refToken })
      .pipe(
        tap(({ token, refreshToken }: Token) => {
          this.storeTokens(token, refreshToken);
        })
      );
  }

  storeTokens(token: string, refreshToken: string): void {
    localStorage.setItem('token', token);
    localStorage.setItem('refreshToken', refreshToken);
  }

  onRefreshTokenExpired(): void {
    this.modalService.removeAllModals();
    this.removeTokens();
    this.loaderService.hide();
    this.router.navigate(['login']);
  }

  removeTokens(): void {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
  }

  setOrganization(): Observable<boolean | void> {
    return this.userService.getCurrentUser()
      .pipe(
        mergeMap((user: User) => {
          if (user.role === Roles.admin || user.role === Roles.adminSales) {
            this.toastr.error('Admin users are not allowed to access this application');
            return this.logout();
          }

          if (user.company?.id) {
            this.setupService.companyId = user.company.id;
            return this.companyService.getCompanyInfo()
              .pipe(
                switchMap(() => {
                  return from(this.router.navigate(['dashboard/company']));
                })
              );
          } else {
            this.enterpriseService.enterpriseId = user.enterprise.id;
            return this.enterpriseService.getEnterpriseInfo()
              .pipe(
                switchMap(() => {
                  return from(this.router.navigate(['dashboard/enterprise']));
                })
              );
          }
        })
      );
  }

  auth2fa(code: string, type: string): Observable<boolean | void> {
    return this.http.post<Token>(environment[`auth${type}`], {
      username: this.userName,
      password: this.userPassword,
      code2fa: +code
    }).pipe(
      tap(({ token, refreshToken }) =>
        this.storeTokens(token, refreshToken)
      ),
      mergeMap(() => this.setOrganization()),
      tap(() => this.userCredentials = { username: '', password: '', })
    );
  }
}
