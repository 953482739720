import { TranslateService } from '@ngx-translate/core';
import { BaseHiringStatus } from '../model/job.interface';

export function setHiringStatusName(status: BaseHiringStatus, translateService: TranslateService): string {
  const key = 'HIRING_STATUSES.' + status.name.toUpperCase();
  const translation = translateService.instant(key);
  return status.nameModified ? status.name : translation;
}

export function convertStringDateToJSDate(date: string): Date {
  const newDate = date.split('.');
  return new Date(+newDate[2], +newDate[1] - 1, +newDate[0]);
}

export function addMinutes(date: Date, numberOfMinutes: number): Date {
  return new Date(date.getTime() + numberOfMinutes*60000);
}

export function removeMinutes(date: Date, numberOfMinutes: number): Date {
  return new Date(date.getTime() - numberOfMinutes*60000);
}

export function addDays(date: Date, numberOfDays: number): Date {
  return new Date(date.setDate(date.getDate() + numberOfDays));
}

export function removeDays(date: Date, numberOfDays: number): Date {
  return new Date(date.setDate(date.getDate() - numberOfDays));
}

export function addMonths(date: Date, numberOfMonths: number): Date {
  return new Date(date.setMonth(date.getMonth() + numberOfMonths));
}

export function dateDiffInDays(a: Date, b: Date): number {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.round((utc2 - utc1) / _MS_PER_DAY);
}

export function dateToUTCStringWithTime(date: Date): string {
  return date.toISOString().replace('T', ' ').replace('.000Z', 'UTC');
}

export function dateToUTCString(date: Date): string {
  return date.toISOString().replace('T', ' ').replace(':00.000Z', 'UTC');
}

export function readQueryParameters(paramName: string): string {
  const url = decodeURIComponent(window.location.href);
  const {queryParams} = checkForQueryParams(url);

  return queryParams[paramName] || '';
}

export function checkForQueryParams(url: string): {urlWithoutParams: string, queryParams: any} {
  const questionMarkIndex = url.indexOf('?');
  const urlLength = url.length;
  let urlWithoutParams = url;
  const queryParams = {};

  const numberOfAmpersand = (url.match(/&/g) || []).length;

  if (questionMarkIndex >= 0) {
    urlWithoutParams = url.slice(0, questionMarkIndex);
    let params = url.slice(questionMarkIndex+1, urlLength);
    for (let i=0; i<numberOfAmpersand+1; i++) {
      const ampersandIndex = params.indexOf('&');
      const endIndex = ampersandIndex >= 0 ? ampersandIndex : undefined;
      const queryUrl = params.slice(0, endIndex);
      const query = queryUrl.split('=');
      const paramName = query[0];
      queryParams[paramName] = query[1];
      if (endIndex) {
        params = params.slice(ampersandIndex+1);
      }
    }
  }

  return { urlWithoutParams, queryParams };
}

export function linkify(url: string): string {
  // http://, https://, ftp://
  const urlPattern = /\b(?:https?|ftp):\/\/[a-z0-9-+&@#\/%?=~_|!:,.;]*[a-z0-9-+&@#\/%=~_|]/gim;

  // www. sans http:// or https://
  const pseudoUrlPattern = /(^|[^\/])(www\.[\S]+(\b|$))/gim;

  // Email addresses
  const emailAddressPattern = /[\w.]+@[a-zA-Z_-]+?(?:\.[a-zA-Z]{2,6})+/gim;

  return url
    .replace(urlPattern, '<a href="$&" target="_blank">$&</a>')
    .replace(pseudoUrlPattern, '$1<a href="http://$2" target="_blank">$2</a>')
    .replace(emailAddressPattern, '<a href="mailto:$&" target="_blank">mailto:$&</a>');
}
