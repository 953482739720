<div class="custom-modal-wrapper">
  <div class="custom-modal">
    <h3 class="modal-title">
      {{ (data.title || "JOBS.TITLE_UNSAVED_CHANGES") | translate }}
    </h3>
    <div class="modal-question-wrapper">
      <div class="modal-question">
        {{ (data.question || "JOBS.ARCHIVE_JOB_QUESTION") | translate }}
      </div>
      <div class="modal-notice" *ngIf="data.notice">
        {{ data.notice  | translate }}
      </div>
    </div>
    <div class="button-wrapper">
      <button (click)="close()"
              class="btn btn-outline-primary btn-back">
        <!-- <i class="icon icon-arrow-left"></i> -->
        <span>{{ "BUTTONS.CANCEL" | translate }}</span>
      </button>
      <button class="btn btn-primary border-white"
              (click)="archive()">
        {{ (data.archiveBtn || "JOBS.ARCHIVE") | translate }}
      </button>
      <button class="btn btn-primary border-white" *ngIf="data.archiveAndFeedbackBtn"
              (click)="archiveAndSendFeedback()">
        {{ data.archiveAndFeedbackBtn | translate }}
      </button>
    </div>
  </div>
</div>
