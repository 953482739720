import { environment } from 'src/environments/environment';
import { ChangeDetectionStrategy, Component, HostListener, OnDestroy, OnInit, Signal, signal } from '@angular/core';
import { SidebarService } from 'src/app/services/sidebar.service';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { NavigationEnd, Router } from '@angular/router';
import { User } from 'src/app/model/user.interface';
import { UserService } from 'src/app/services/user.service';
import { SidemenuCategory } from 'src/app/model/sidemenu-categories.interface';
import { ENTERPRISE_SIDE_BAR } from 'src/app/resources/enterprise-side-bar';
import { COMPANY_SIDE_BAR } from 'src/app/resources/company-side-bar';
import { SetupService } from 'src/app/services/setup.service';
import { expandAnimation1 } from 'src/app/modules/shared/animations/expand.animation';
import { ENTERPRISE_ROLES } from 'src/app/resources/roles';
import { fadeAnimation } from 'src/app/modules/shared/animations/fade.animation';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: 'sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: [expandAnimation1, fadeAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class SidebarComponent implements OnInit, OnDestroy {

  sideMenuCategories: SidemenuCategory[] = [];
  companyId: number;
  isEnterpriseUser: boolean;
  isEnterprise = false;
  host = window.location.host;
  scrollBarWidth = 0;
  toggleButtonWidth = '10rem';
  isSidebarOpened: Signal<boolean> = signal(false);

  readonly applicationVersion = environment.appVersion;

  private _ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private sidebarService: SidebarService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private userService: UserService,
    private setupService: SetupService,
    private modalService: ModalService
  ) {
    router.events
      .pipe(
        takeUntil(this._ngUnsubscribe$),
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe(({url}: NavigationEnd) => {
        this.sidebarService.close();

        this.setSideMenuCategories(url);
      });
  }

  get user(): User {
    return this.userService.user;
  }

  // add hostlistener in order to trigger change detection on window resize so hasScrollbar method would be triggered on window resize
  @HostListener('window:resize')
  sidebarSizeUpdated(): void {
    this.setScrollBarWidth();
    this.setToggleButtonWidth();
  }

  setToggleButtonWidth(): void {
    this.toggleButtonWidth = window.innerWidth > 1025 ? '10rem' : '5rem';
  }

  ngOnInit(): void {
    this.isSidebarOpened = this.sidebarService.sidebarStatus;
    this.isEnterpriseUser = ENTERPRISE_ROLES.includes(this.userService.role);
    this.setSideMenuCategories(this.router.url);

    this.sideMenuCategories
      .forEach((category: SidemenuCategory) => {
        category.links.forEach((link: string) => {
          if (this.router.url.includes(link)) {
            category.expand = true;
            return;
          }
        });
      });

    this.setScrollBarWidth();
    this.setToggleButtonWidth();
  }

  setSideMenuCategories(url: string): void {
    if (url.includes('enterprise') && this.sideMenuCategories !== ENTERPRISE_SIDE_BAR) {
      this.isEnterprise = true;
      this.sideMenuCategories = ENTERPRISE_SIDE_BAR;
    } else if (!url.includes('enterprise')) {
      let companySideBar = COMPANY_SIDE_BAR;
      if (!this.setupService.areInterviewsEnabled) {
        companySideBar = companySideBar.filter(category => category.title !== 'SIDEBAR.INTERVIEWS_BOARD_TITLE');
      }
      if (!this.setupService.isRequisitionFormFeatureAllowed) {
        companySideBar = companySideBar.filter(category => category.title !== 'SIDEBAR.REQUESITION_FORM');
      }
      if (!this.setupService.currentCompany?.isAllowedAlternativeJobFeature) {
        companySideBar = companySideBar.filter(category => category.title !== 'SIDEBAR.ALTERNATIVE_JOBS');
      }

      if (JSON.stringify(this.sideMenuCategories) !== JSON.stringify(companySideBar)) {
        this.isEnterprise = false;
        this.sideMenuCategories = companySideBar;
      }
    }

    this.sideMenuCategories.forEach((category) => {
      if (category.title === 'SIDEBAR.SETTINGS_TITLE') {
        const index = category.children.findIndex((subCategory) => subCategory.title === 'SIDEBAR.AGREEMENT_FORM_LIST');
        if (index !== -1) {
          category.children[index].hide = !this.setupService.areAgreementsEnabled || !this.userService.user.allowedAgreementForms;
        }
      }
    });

    if (!this.isEnterprise) {
      this.companyId = this.setupService.companyId;
    }
  }

  expandCategory(category: SidemenuCategory): void {
    category.expand = !category.expand;
  }

  onLogout(): void {
    this.authenticationService.beforeLogout();
  }

  toggleSidebar(): void {
    this.isSidebarOpened() ? this.sidebarService.close() : this.sidebarService.open();

    this.setScrollBarWidth();
  }

  closeSidebar(): void {
    if (this.isSidebarOpened()) {
      this.sidebarService.close();
    }
  }

  animationDone(): void {
    this.setScrollBarWidth();
  }

  setScrollBarWidth(): void {
    this.scrollBarWidth = this.modalService.getScrollbarWidth();
  }

  backToEnterprise(): void {
    this.router.navigate(['dashboard/enterprise']);
  }

  ngOnDestroy(): void {
    this._ngUnsubscribe$.next();
    this._ngUnsubscribe$.complete();
  }
}
